.leftContent {
  width: 37%;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-bg);
}
.rightContent {
  width: 63%;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 2em;
}

.armChair {
  width: 90% !important;
  position: relative !important;
  object-fit: contain;
}

.authenticated {
  display: flex;
  flex-direction: column;
}

.formDiv {
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.button {
  background-color: var(--primary-purple);
  border-color: var(--primary-purple);
  width: 100% !important;
  color: white;
  border: none;
  height: 3rem;
  border-radius: 3px;
  font-size: 16px;
  font-family: "Editorial";
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  opacity: 0.8;
  transform: translateY(-1px);
}

.googleButton {
  width: 100%;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px !important;
  border-radius: 7px !important;
  text-align: center !important;
  padding: 0.5em !important;
  font-size: 0.8em !important;
  height: 2.7em;
  display: inline !important;
}

.googleButton div {
  margin-left: 10px;
  padding: 0 !important;
  display: inline;
}

.googleButton span {
  /* margin-left: 10px; */

  padding: 0 !important;
  display: inline;
}

.absolute {
  display: inline-block;
  position: fixed;
  font-size: 14px;
  right: 1%;
  top: 40px;
  width: 15%;
}

.spaceTab {
  display: inline-block;
  width: 2px;
}

.Link {
  font-weight: 600;
  color: var(--primary-purple);
  text-decoration: none;
}

.link:hover {
  color: var(--primary-purple);
}

.header {
  width: 360px;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}

.headerText {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.leftSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-top: 2em;
  padding-right: 3em;
}

.helpLinks {
  margin-top: 1em;
  font-size: 14px;
  display: flex;
  width: fit-content;
  position: inherit;
  margin-left: auto;
  margin-right: auto;
}

.logIn {
  font-weight: 500;
  margin-bottom: 0rem;
  font-size: 18px;
  color: var(--primary-purple);
}

.logIn:hover {
  color: var(--primary-purple);
  opacity: 0.8;
}

.color {
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0rem;
}

.color:hover {
  color: var(--primary-purple);
  opacity: 0.8;
}

@media (max-width: 1000px) {
  .absolute {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 2rem;

    width: 30%;
  }
  .logIn {
    font-size: 16px;
  }

  .headerText {
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .leftContent {
    display: none;
  }

  .rightContent {
    width: 100%;
    height: 100%;
  }

  .header {
    width: 85%;
    max-width: 30em;
  }

  .formDiv {
    width: 85%;
    max-width: 30em;
  }
}

.linkBox {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.headerParagraph {
  color: var(--text-mute);

  margin-bottom: 1.5em;
}

.headerParagraph1 {
  color: var(--text-main);
  margin-bottom: 1em;
  font-size: 18px;
  text-decoration: none;
}

.headerParagraph1:hover {
  color: var(--primary-purple);
  font-weight: 600;
}

.logo {
  width: 50% !important;
  position: relative !important;
  margin-top: 4em;
  height: auto !important;
  object-fit: contain;
  margin-bottom: 2em;
}

.text {
  font-size: 25px;
  font-weight: 400;
  width: 95%;
  margin-bottom: 3rem;
  font-family: "Editorial";
  color: var(--text-body);
}

.logo:hover {
  cursor: pointer;
}

.inputContainer {
  position: relative;
  width: 100%;
}

.lense {
  position: absolute;
  right: 2%;
  top: 25%;
  color: gray;
}

.lense:hover {
  color: black;
  transform: scale(1.1);
}

.main {
  height: 100vh; /* You must set a specified height */
  background-repeat: no-repeat; /* Do not repeat the image */
  overflow: auto;
  display: flex;
}
.newListingDiv {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 2em;
  padding: 1em;
  position: relative;
}

.visibleAtWidth {
  display: none;
}

@media (max-width: 900px) {
  .visibleAtWidth {
    display: flex;
  }

  .logo {
    width: 100%;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}

.newListingHeader {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.listingPicture {
  max-width: 6em;
  border-radius: 10px;
  display: inline;
}

.listingTitle {
  display: inline;
  margin-left: 1em;
  font-weight: 600;
  color: var(--primary-purple);
}

.listingPrice {
  position: absolute;
  top: 3em;
  right: 2em;
  color: var(--primary-text-color);
  font-weight: 800;
  font-size: 1.5em;
}

.separator {
  margin-top: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.separatorText {
  color: #99a3a6;
  padding: 0 0.5rem;
  margin-bottom: 0rem !important;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #99a3a6;
}
